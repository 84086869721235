<template>
	<div class="p-grid p-justify-center">
		<div class="p-col-12 p-md-12 p-d-flex p-jc-center">
			<img src="logo_cuadrado.png" style="width:30vh; height:25vh;" v-if="!$store.state.autenticado"/>
			<img src="logo_cuadrado.png" v-else/>
		</div>
		<div class="p-col-12 p-md-4">
			<Login @aprob-login="aprob" @close-login="close" v-if="!$store.state.autenticado"/>
		</div>
	</div>
</template>

<script>
	import { defineAsyncComponent } from "@vue/runtime-core";

	export default {
		components: {
			Login: defineAsyncComponent(() => import('../components/Login.vue')),
		},

		data() {
			return {
			}
		},
		created() {
			this.url = process.env.VUE_APP_NOMBRE;
		},
		methods: {
		},
	}
</script>

<style scoped>

</style>
