<template>
	<div class="card p-grid">
		<div class="p-col-12">	
			<h4>{{$store.state.titulo}}</h4>
			<DataTable :value="products" scrollable tableStyle="min-width: 50rem" :paginator="false" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
				<template #header>
					<div class="table-header">
						<div>Registros: {{ products.length }}</div>
						<div>
							<Dropdown v-model="opcion" :options="opciones" optionLabel="name" placeholder="Seleccionar..." style="width:200px;"/>
							<InputText type="number" min="0" v-model="buscar" style="width:250px;" required="true" v-if="opcion.code==1"  class="p-mr-2 p-mb-2"/>
							<InputText type="text" v-model="buscar" style="width:250px;" required="true" v-if="opcion.code>1"  class="p-mr-2 p-mb-2"/>
						</div>
						<div>
							<Button @click="Buscar" icon="pi pi-search" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="'Buscar'"/>
							<Button @click="Nuevo" icon="pi pi-plus" class="p-button-rounded p-button-info p-mr-2 p-mb-2" v-tooltip.bottom="'Editar'" v-if="insert"/>
						</div>
					</div>
				</template>
				<Column field="id" header="ID">
					<template #body="slotProps">
						{{slotProps.data.id}}
					</template>
				</Column>
				<Column field="nombre" header="Nombre" :sortable="true">
					<template #body="slotProps">
						{{slotProps.data.nombre}}
					</template>
				</Column>
				<Column field="apellido" header="Apellido" :sortable="true">
					<template #body="slotProps">
						{{slotProps.data.apellido}}
					</template>
				</Column>
				<Column field="correo" header="Correo" :sortable="true"  headerStyle="width: 250px">
					<template #body="slotProps">
						{{slotProps.data.correo}}
					</template>
				</Column>
				<Column field="activo" header="Estatus">
					<template #body="slotProps">
						<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
						<span class="p-tag p-tag-danger" v-else>No Activo</span>
					</template>
				</Column>
				<Column header="Opciones">
					<template #body="slotProps">
						<Button @click="Editar(slotProps.data)" icon="pi pi-pencil" class="p-button-rounded p-button-info p-mr-2 p-mb-2" v-tooltip.bottom="'Editar'" v-if="edit"/>
						<Button @click="Confirmar(slotProps.data)" icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2 p-mb-2" v-tooltip.bottom="'Borrar'" v-if="del"/>
					</template>
				</Column>
			</DataTable>
		</div>

		<Dialog v-model:visible="dialog_edit" :style="{width: size}" :header="'Editar - ID: '+product.id" :modal="true" class="p-fluid" :maximizable="true">
            <br>
			<div class="p-field p-grid">
				<div class="p-col-12 p-md-12 p-lg-6 p-mb-3">
					<label for="name">Nombre</label>
					<InputText id="name" v-model="product.nombre" required="true" autofocus/>
					<small v-show="!product.nombre" class="p-error">Obligatorio</small>
				</div>
				<div class="p-col-12 p-md-12 p-lg-6 p-mb-3">
					<label for="name">Apellido</label>
					<InputText id="name" v-model="product.apellido" required="true"/>
					<small v-show="!product.apellido" class="p-error">Obligatorio</small>
				</div>
				<div class="p-col-12 p-md-12 p-lg-10 p-mb-3">
					<label for="name">Correo</label>
					<InputText id="name" v-model="product.correo" type="email" required="true"/>
					<small v-show="!product.correo" class="p-error">Obligatorio</small>
				</div>
				<div class="p-col-12 p-md-12 p-lg-2 p-mb-3">
					<h6 style="margin-top: 0" v-if="product.activo">Activo</h6>
					<h6 style="margin-top: 0" v-else>No Activo</h6>
					<InputSwitch v-model="product.activo" />
				</div>
				<div class="p-col-12 p-md-12 p-lg-12 p-mb-3">
					<div class="p-grid p-mb-3">
						<div class="p-col-12  p-mb-3" >
							<h5>Permisología</h5>
						</div>
						<div class="p-col-6  p-mb-3"  v-for="(stats, index) in product.roles" :key="index">
							<div class="p-grid">
								<div class="p-col-8">
									{{stats.nombre}}
								</div>
								<div class="p-col-4">
									<InputSwitch :id="index" v-model="product.roles[index].activo" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" />
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="dialog_edit=false"/>
			</template>
		</Dialog>
		<Dialog v-model:visible="dialog_del" :style="{width: size2}" header="Confirmar" :modal="true">
			<br>
			<div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">¿Estás seguro que quieres eliminar?</span>
			</div>
			<br><br>
			<div class="confirmation-content">
				<div v-if="product" class="p-mb-3">{{ product.nombre }}</div>
			</div>
			<template #footer>
				<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="Borrar()" />
				<Button label="No" icon="pi pi-times" class="p-button-danger" @click="dialog_del = false"/>
			</template>
		</Dialog>
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Configuracion','Usuarios');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				buscar: null,
				opcion: {name: 'Todos', code: 0},
				opciones: [
					{name: 'Todos', code: 0},
					{name: 'ID', code: 1},
					{name: 'Nombre o Apellido', code: 2},
					{name: 'Correo', code: 3}
				],
				product: {},
				products: [],
				filters: {},
				selectedCustomers: null,
				dialog_edit: false,
				dialog_del: false,
				size: '60vw',
				size2: '40vw',
				roles: [],
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){
				this.size = '90vw';
				this.size2 = '90vw';
			}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.roles = response.result;
				}); 
			},
			Buscar() {
				this.products = [];
				Consulta.Procesar('Buscar',{
					product: {opcion: this.opcion.code, code: this.buscar},
				}).then(response => {
					//this.$store.state.error =response;
					this.products = response.result;
				}); 
			},
			Nuevo() {
				let roles = this.roles;
				this.product = {};
				this.product.id = 0;
				this.product.activo = false;
				this.product.roles = {...roles};
				this.dialog_edit = true;
			},
			Editar(product) {
				this.product = {...product};
				this.dialog_edit = true;
			},
			Confirmar(product){
				this.product = {...product};
				this.dialog_del = true;
			},
			Validacion(){
				let estatus = false;
				if (this.product.nombre == null || this.product.nombre == '') {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Nombre', life: 5000});
				} else if (this.product.apellido == null || this.product.apellido == '') {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Apellido', life: 5000});
				} else if (this.product.correo == null || this.product.correo == '') {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Correo', life: 5000});
				} else {
					estatus = true;
				}
				return estatus;
			},
			Guardar() {
				if (this.Validacion()) {	
					let caso = 'INS';
					if (this.product.id) {caso = 'UPD';} 
					Consulta.Procesar(caso,{
						product: 	this.product,
					}).then(response => {
						//this.$store.state.error = response;
						this.$toast.add(Consulta.Mensaje(response.result));
						if (response.result) {
							this.dialog_edit = false;
							this.product = {};
							this.Buscar();
						}
					});		
				}
			},
			Borrar(){
				Consulta.Procesar('DEL',{
					product: this.product,
				}).then(response => {
					//this.$store.state.error = response;
					this.$toast.add(Consulta.Mensaje(response.result));
					if (response.result) {
						this.dialog_del = false;
						this.product = {};
						this.Buscar();
					}
				});	
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
