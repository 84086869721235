<template>
	<div class="card p-grid">
		<div class="p-col-12">	
			<h4>{{$store.state.titulo}}</h4>
			<DataTable :value="products" scrollable tableStyle="min-width: 50rem" :paginator="false" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
				<template #header>
					<div class="table-header">
						<div>Registros: {{ products.length }}</div>
						<div>
							<Dropdown v-model="opcion" :options="opciones" optionLabel="name" placeholder="Seleccionar..." style="width:200px;"/>
							<InputText type="number" min="0" v-model="buscar" style="width:250px;" required="true" v-if="opcion.code==1 || opcion.code==3"  class="p-mr-2 p-mb-2"/>
							<InputText type="text" v-model="buscar" style="width:250px;" required="true" v-if="opcion.code==2 || opcion.code==4"  class="p-mr-2 p-mb-2"/>
						</div>
						<div>
							<Button @click="Buscar" icon="pi pi-search" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="'Buscar'"/>
						</div>
					</div>
				</template>
				<Column field="id" header="ID"  headerStyle="width: 70px">
					<template #body="slotProps">
						{{slotProps.data.id}}
					</template>
				</Column>
				<Column field="nombre" header="Nombre" :sortable="true">
					<template #body="slotProps">
						{{slotProps.data.nombre}}
					</template>
				</Column>
				<Column field="apellido" header="Apellido" :sortable="true">
					<template #body="slotProps">
						{{slotProps.data.apellido}}
					</template>
				</Column>
				<Column field="correo" header="Correo" :sortable="true">
					<template #body="slotProps">
						{{slotProps.data.correo}}
					</template>
				</Column>

				<Column field="ingreso" header="Último Ingreso" :sortable="true"  headerStyle="width: 250px">
						<template #body="slotProps">
							{{slotProps.data.ingreso}}
						</template>
					</Column>
				<Column header="Ver" headerStyle="width: 100px">
					<template #body="slotProps">
						<Button icon="pi pi-eye" class="p-button-rounded p-button-success p-mr-2" @click="Ver(slotProps.data)" v-if="edit" v-tooltip.bottom="'Ver'"/>
					</template>
				</Column>
			</DataTable>
		</div>

		<Dialog v-model:visible="dialog_edit" :style="{width: size}" :header="'Registros: '+detalles.length" :modal="true" class="p-fluid" :maximizable="true">
			<br>
            <div class="p-field">
				<DataTable :value="detalles" scrollable class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
					scrollHeight="300px" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters2">
					<template #header>
						<div class="table-header">
							<span class="p-input-icon-left">
								<i class="pi pi-search"/>
								<InputText v-model="filters2['global']" placeholder="Buscar..." />
							</span>
						</div>
					</template>
					<Column field="id" header="ID" :sortable="true" headerStyle="width: 70px">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="fecha" header="Fecha" :sortable="true" headerStyle="width: 150px">
						<template #body="slotProps">
							{{slotProps.data.date_reg}}
						</template>
					</Column>
					<Column field="usuario" header="Usuario" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.usuario}}
						</template>
					</Column>
					<Column field="tabla" header="Tabla" :sortable="true" headerStyle="width: 150px">
						<template #body="slotProps">
							{{slotProps.data.tabla}}
						</template>
					</Column>
					<Column field="code" header="ID de Registro" :sortable="true" headerStyle="width: 150px">
						<template #body="slotProps">
							{{slotProps.data.code}}
						</template>
					</Column>
					<Column field="accion" header="Acción" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.accion}}
						</template>
					</Column>
				</DataTable>			
			</div>
		</Dialog>
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Configuracion','Auditar');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				buscar: null,
				opcion: {name: 'Usuario en Línea', code: 0},
				opciones: [
					{name: 'Usuario en Línea', code: 0},
					{name: 'ID de Usuario', code: 1},
					{name: 'Nombre o Apellido', code: 2},
					{name: 'ID de Registro', code: 3},
					{name: 'Nombre de Tabla', code: 4},
				],
				product: {},
				products: [],
				filters: {},
				filters2: {},
				selectedCustomers: null,
				dialog_edit: false,
				dialog_del: false,
				size: '60vw',
				size2: '40vw',
				roles: [],
				detalles: [],
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){
				this.size = '90vw';
				this.size2 = '90vw';
			}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.products = response.result;
				}); 
			},
			Buscar() {
				this.detalles = [];
				this.products = [];
				Consulta.Procesar('Buscar',{
					product: {opcion: this.opcion.code, code: this.buscar},
				}).then(response => {
					//this.$store.state.error =response;
					if(response.result){
						if(this.opcion.code==3 || this.opcion.code==4){
							this.detalles = response.result;
							this.dialog_edit = true;
						} else {
							this.products = response.result;
						}
					}
				}); 
			},
			Ver(data) {
				this.detalles = [];
				Consulta.Procesar('Ver',{
					product: 	data,
				}).then(response => {
					//this.$store.state.error =response.result;
					this.detalles = response.result;
					this.dialog_edit = true;
				}); 
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
