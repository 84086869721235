<template>
	<div class="card p-grid">
		<div class="p-col-12">	
			<h4>{{$store.state.titulo}}</h4> 
			<DataTable :value="products" scrollable tableStyle="min-width: 50rem" :paginator="false" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
				<template #header>
					<div class="table-header">
						<div>Registros: {{ products.length }}</div>
                        <Dropdown v-model="almacen" :options="almacenes" optionLabel="nombre" placeholder="Seleccionar almacén ..." @change="Buscar"/>       
						<span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global']" placeholder="Buscar..." />
                        </span>
						<div>
							<Button @click="Buscar" icon="pi pi-search" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="'Buscar'" :disabled="almacen==null"/>
							<Button @click="Nuevo" icon="pi pi-plus" class="p-button-rounded p-button-info p-mr-2 p-mb-2" v-tooltip.bottom="'Editar'" v-if="insert" :disabled="almacen==null"/>
						</div>
					</div>
				</template>
				<Column field="date" header="Fecha" headerStyle="width: 10%">
					<template #body="slotProps">
						{{slotProps.data.date}}
					</template>
				</Column>
				<Column field="nombre" header="Producto" :sortable="true">
					<template #body="slotProps">
						{{slotProps.data.nombre}}
					</template>
				</Column>
				<Column field="codigo" header="Código" headerStyle="width: 15%">
					<template #body="slotProps">
						{{slotProps.data.codigo}}
					</template>
				</Column>
				<Column field="id" header="Lote" headerStyle="width: 7%">
					<template #body="slotProps">
						{{slotProps.data.id}}
					</template>
				</Column>
				<Column field="vencimiento" header="Vencimiento" headerStyle="width: 15%">
					<template #body="slotProps">
						<strong v-if="slotProps.data.dias>0" style="color: green;">
							{{slotProps.data.vence}} - {{ slotProps.data.dias+' días' }}
						</strong>
						<strong v-else style="color: red;">
							{{slotProps.data.vence}}
						</strong>					
					</template>
				</Column>
				<Column field="cantidad" header="Cantidad" headerStyle="width: 7%">
					<template #body="slotProps">
						{{slotProps.data.cantidad}}
					</template>
				</Column>
				<Column field="disp" header="Disp." headerStyle="width: 7%">
					<template #body="slotProps">
						{{slotProps.data.disp}}
					</template>
				</Column>
				<Column header="Opciones" headerStyle="width: 7%">
					<template #body="slotProps">
						<Button @click="Confirmar(slotProps.data)" icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2 p-mb-2" v-tooltip.bottom="'Borrar'" v-if="del"/>
					</template>
				</Column>
			</DataTable>
		</div>

		<Dialog v-model:visible="dialog_edit" :style="{width: size}" header="Agregar a Almacén" :modal="true" class="p-fluid" :maximizable="true">
            <br>
			<div class="p-field p-grid" style="min-height: 20vh;">
                <div class="p-col-4 p-md-4 p-lg-4 p-mb-3">
                    <SelectButton v-model="opcion" :options="opciones" optionLabel="nombre"/>
                </div>
                <div class="p-col-8 p-md-8 p-lg-8 p-mb-3" v-if="opcion.id==1">
					<InputText id="name" v-model="codigo" type="number" @keyup.enter="BuscarProdct(1)" autofocus/>
					<small v-show="!codigo" class="p-error">Obligatorio</small>
				</div>	
                <div class="p-col-8 p-md-8 p-lg-8 p-mb-3" v-else>
                    <Dropdown v-model="nombre" :options="productos" optionLabel="nombre" filter  placeholder="Seleccionar..." @change="BuscarProdct(2)" autofocus/>
					<small v-show="!nombre" class="p-error">Obligatorio</small>
                </div>
                <div class="p-col-12 p-md-12 p-lg-8 p-mb-3" v-if="product.data">
                    <BarcodeGenerator :value="product.data.codigo" :format="product.data.format" :lineColor="'#000'" :width="2" :height="40" :elementTag="'img'" :key="actualizar"/>
                </div>
                <div class="p-col-12 p-md-12 p-lg-4 p-mb-3" v-if="product.data">
                    <strong>{{product.data.nombre}}</strong>               
                </div>
            </div>

            <div class="p-field p-grid" style="min-height: 20vh;">
                <div class="p-col-6 p-md-6 p-lg-4 p-mb-3" v-if="product.data">
					<label for="name">Fecha</label>
					<InputText id="name" v-model="product.fecha" type="date" required="true"/>
					<small v-show="!product.fecha" class="p-error">Obligatorio</small>
				</div>
				<div class="p-col-4 p-md-4 p-lg-4 p-mb-3" v-if="product.data">
					<label for="name">Cantidad</label>
					<InputText id="name" v-model="product.cantidad" type="number" min="1"/>
					<small v-show="!product.cantidad" class="p-error">Obligatorio</small>
				</div>
				<div class="p-col-6 p-md-6 p-lg-4 p-mb-3" v-if="product.data">
					<label for="name">Vencimiento</label>
					<InputText id="name" v-model="product.vencimiento" type="date" required="true"/>
					<small v-show="!product.vencimiento" class="p-error">Obligatorio</small>
				</div>
                <div class="p-col-12 p-md-12 p-lg-12 p-mb-3" v-if="product.data">
					<label for="name">Observación</label>
                    <Textarea v-model="product.observacion" autoResize rows="2" style="width: 100%;"/>
                </div>
			</div>

			<template #footer>
				<Button label="Agregar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" />
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="dialog_edit=false"/>
			</template>
		</Dialog>
		<Dialog v-model:visible="dialog_del" :style="{width: size2}" header="Confirmar" :modal="true">
			<br>
			<div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">¿Estás seguro que quieres eliminar?</span>
			</div>
			<br><br>
			<div class="confirmation-content">
				<div v-if="product" class="p-mb-3">{{ product.nombre }}</div>
			</div>
			<template #footer>
				<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="Borrar()" />
				<Button label="No" icon="pi pi-times" class="p-button-danger" @click="dialog_del = false"/>
			</template>
		</Dialog>

	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Inventario','Ingreso');
	import BarcodeGenerator from "../components/BarcodeGenerator.vue";

	export default {
		components: {
			BarcodeGenerator
		},
		data() {
			return {
				actualizar: 0,
				insert: false,
				edit: false,
				del: false,
				product: {},
				products: [],
				filters: {},
				selectedCustomers: null,
				dialog_edit: false,
				dialog_del: false,
				size: '40vw',
				size2: '40vw',
				productos: [],
                almacenes: [],
                opcion: {id: 1, nombre: 'Código'},
                opciones: [
                    {id: 1, nombre: 'Código'},
                    {id: 2, nombre: 'Nombre'},
                ],
                codigo: null,
                nombre: null,
                almacen: null,
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){
				this.size = '90vw';
				this.size2 = '90vw';
			}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.almacenes = response.result.almacenes;
					this.productos = response.result.productos;
					this.Actualizar();
				}); 
			},
			Buscar() {
				this.products = [];
				Consulta.Procesar('Buscar',{
					product: this.almacen,
				}).then(response => {
					//this.$store.state.error =response;
					this.products = response.result;
				}); 

			},
            BuscarProdct(opcion){
                this.Actualizar();
                if(opcion==1){
                    let producto = this.productos.filter(val => val.codigo == this.codigo);
                    if(producto.length>0){
                        this.product.data = producto[0];
                        this.product.producto = producto[0].id;
                    } else {
                        this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Código no registrado', life: 5000});
                    }
                } else {
                    this.product.data = this.nombre;
                    this.product.producto = this.nombre.id;
                }
            },
			Nuevo() {
				this.nombre = null;
				this.product = {};
                this.product.almacen = this.almacen.id;
				this.product.data = null;
                this.product.observacion = null;
				this.dialog_edit = true;
			},
            Editar() {
			},
			Confirmar(product){
				this.product = {...product};
				this.dialog_del = true;
			},
			Validacion(){
				let estatus = false;
				if (this.product.data == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca producto', life: 5000});
				} else if (this.product.cantidad == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la cantidad', life: 5000});
				} else if (this.product.fecha == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la fecha de ingreso', life: 5000});
				} else if (this.product.vencimiento == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la fecha de vencimiento', life: 5000});
				} else {
					estatus = true;
				}
				return estatus;
			},
			Guardar() {
				if (this.Validacion()) {	
					let caso = 'INS';
					if (this.product.id) {caso = 'UPD';} 
					Consulta.Procesar(caso,{
						product: 	this.product,
					}).then(response => {
						//this.$store.state.error = response;
						this.$toast.add(Consulta.Mensaje(response.result));
						if (response.result) {
							this.dialog_edit = false;
							this.product = {};
							this.Buscar();
						}
					});		
				}
			},
			Borrar(){
				Consulta.Procesar('DEL',{
					product: this.product,
				}).then(response => {
					//this.$store.state.error = response;
					this.$toast.add(Consulta.Mensaje(response.result));
					if (response.result) {
						this.dialog_del = false;
						this.product = {};
						this.Buscar();
					}
				});	
			},
			Actualizar(){
				this.actualizar += 1;
			}
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
