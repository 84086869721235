import jsXLS from "js-export-excel";

class XLSjs {
    convfech(fecha){
        return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    }

    Reporte(data,ini,fin) {
        var option = {};
        option.fileName = "Reporte "+this.convfech(ini)+' - '+this.convfech(fin);
        var body = [];
    
        for (var i = 0; i < data.length; i++) {
			body.push({
                C1: i+1,
                C2: data[i].date+' '+data[i].inv,
                C3: data[i].codigo.toString(),
                C4: data[i].nombre,
                C5: data[i].tipo_n,
                C6: data[i].unidad_n,
                C7: data[i].cantidad,
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7"],
            sheetHeader: ['Nº','Fecha', 'Código', 'Producto','Tipo','Unidad','Cantidad'],
            columnWidths: [2, 10, 10, 15, 10, 10, 5],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

}


export default XLSjs;