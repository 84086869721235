import jsPDF from 'jspdf';
import 'jspdf-autotable';
//import QR from "qr-encode";
import JsBarcode from "jsbarcode";

var pdf = new jsPDF('p', 'mm', [297, 210]);

class PDFs {

    Plantilla(EXTENSION){
        pdf.addImage("logo.jpg", 'JPEG', 5, 5, 15, 15);
        pdf.setFontSize(12);
        pdf.setFont("italic", "bold");
        pdf.text(100, 10, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL" , 'center');
        pdf.text(100, 15, "RODOLFO LOERO ARISMENDI", 'center');
        pdf.text(100, 20,'EXTENSIÓN: '+EXTENSION, 'center');
        /*
        pdf.setFont("italic","normal");
        pdf.text(100, 284, 'Las Acacias - Caracas', 'center');
        pdf.text(100, 287, 'Tlf '+empresa.tlflocal, 'center');
        pdf.text(100, 290, 'Correo: '+empresa.correo, 'center');
        */
    }
    

    Tabla(margen,altura,titulos,filas){
        pdf.autoTable({
            head: titulos,
            body: filas,
            startY: altura,
            margin: margen,
            headerStyles: {fillColor: [0, 0, 0]},                     
            styles: {
                //font: 'courier',
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                columnWidth: 'wrap',
                halign: 'center', // left, center, right
            },
        });
    }

    Out(){
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(pdf.output("blob"),"consulta.pdf");
        } else {
            //pdf.autoPrint();
            window.open(
                URL.createObjectURL(pdf.output("blob")),
                "_blank",
                "height=650,width=500,scrollbars=yes,location=yes"
            );
            setTimeout(() => {    
                window.URL.revokeObjectURL(pdf.output("bloburl"));
            }, 100);
        }
        pdf = new jsPDF();      
    }

    moneda(number){
        return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);

    }

    convfech(fecha){
        return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    }
   
    Reporte(data,ini,fin) {
        pdf = new jsPDF('p', 'mm', 'letter');
        //this.Plantilla(matricula.extension);     
        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text(100, 20, "REPORTE", 'center');
        pdf.text(100, 25, "Desde "+this.convfech(ini)+' hasta '+this.convfech(fin), 'center');

        const head = [['Nº','Fecha', 'Código', 'Producto','Tipo','Unidad','Cantidad']];
        var body = [];
        for (var i = 0; i < data.length; i++) {
			body.push([
                i+1,
                data[i].date+' '+data[i].inv,
                data[i].codigo,
                data[i].nombre,
                data[i].tipo_n,
                data[i].unidad_n,
                data[i].cantidad,
            ]);
        }

        pdf.autoTable({
            head: head,
            body: body,
            startY: 40,
            margin: 10,
            headerStyles: {fillColor: [0, 0, 0]},                     
            styles: {
                fontSize: 7,
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                columnWidth: [5,10,15,70,5,80],
                halign: 'center', // left, center, right
            },
        });

        this.Out();
    }

    Etiqueta(data) {
        pdf = new jsPDF('l', 'mm', [50.5, 30]);
        pdf.setFontSize(8);
        pdf.setFont("italic", "bold");
        pdf.text(20, 5, ""+data.nombre, {maxWidth: 100, align:'center'});
        JsBarcode("#barcode_id", data.codigo, {
            format: data.format,
            width:1,
            height:50,
            font: "monospace",
            displayValue: true,
            lineColor: "#000000"
        });
        var imagen = document.getElementById('barcode_id').src;
        var img = new Image();
        img.src = imagen;
        var qr = img.src;

        pdf.addImage(qr, 'JPEG', 1, 8, 50, 15);
    






        this.Out();
    }

}

export default PDFs;